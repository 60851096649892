export const environment = {
  production: false,
  env: "dev",
  apiEndpoint: 'https://api.crm.manubhoomi.info',
  // IFSC Search API url
  ifscSearch: 'https://bank-apis.justinclicks.com/API/V1/IFSC/',
  //Set datasource to 'dummy' if API is not working. API's will start fetching data from aasets/dummydb/<respective file>.json
  //to fetch data from actual API just set it anything except 'dummy' or set it to blank
  isDummyDataSource: false,
  dummyDbPath: "assets/dummydb/",

  decumentVerificationSource: 'deepvue',
  deepVueAPIUrl: 'https://production.deepvue.tech/v1/',
  assetsImagePath: "assets/images/"
};